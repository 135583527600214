<script lang="ts">
	import StaticPictureInner from "./StaticPictureInner.svelte";
	import type { SourceSetQuery } from "../../utils/SourceSetQuery.js";
	import { sortSourceSetQuery } from "../../utils/sortSourceSetQuery.js";
	import { parseSourceSet } from "../../utils/parseSourceSet.js";

	let className = "";
	export { className as class };

	export let width: number;
	export let height: number;
	export let imgClass = "";
	export let loading: "eager" | "lazy" = "lazy";
	export let image: string;
	export let alt: string;
	export let sourcesets: Partial<Record<SourceSetQuery, string>> = {};
	export let preload: boolean | undefined = undefined;
	export let lightboxId = undefined as string | undefined;
	export let naturalHeight: number | undefined = undefined;
	export let naturalWidth: number | undefined = undefined;
	export let imageOver: string | undefined = undefined;
	export let imageOverFlyIn: boolean | undefined = false;
	export let imgOverClass: string | undefined = undefined;

	$: sortedSourcesets = Object.entries(sourcesets).toSorted(([firstQuery], [secondQuery]) =>
		sortSourceSetQuery(firstQuery as SourceSetQuery, secondQuery as SourceSetQuery),
	) as [SourceSetQuery, string][];
</script>

<svelte:head>
	{#if preload}
		{#each sortedSourcesets as [query, image]}
			<link rel="preload" as="image" imagesrcset={`${image} 1x`} media={parseSourceSet(query)} fetchpriority="high" />
		{/each}
	{/if}
</svelte:head>

{#if lightboxId}
	<a
		class="block {className}"
		aria-label={alt}
		href={image}
		data-fslightbox={lightboxId}
		data-type="image"
		data-caption={alt}
	>
		<StaticPictureInner
			{width}
			{height}
			{imgClass}
			{loading}
			{image}
			{alt}
			{sourcesets}
			hasLightbox
			bind:naturalHeight
			bind:naturalWidth
		/>
	</a>
{:else}
	<div class={className}>
		{#if imageOver}
			<StaticPictureInner
				imgClass="absolute inset-0 w-full h-full object-cover z-10 {imgOverClass}"
				flyIn={imageOverFlyIn}
				{width}
				{height}
				{loading}
				image={imageOver}
				{alt}
				{sourcesets}
				bind:naturalHeight
				bind:naturalWidth
			/>
		{/if}
		<StaticPictureInner
			{width}
			{height}
			{imgClass}
			{loading}
			{image}
			{alt}
			{sourcesets}
			bind:naturalHeight
			bind:naturalWidth
		/>
	</div>
{/if}
